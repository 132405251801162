import React, { ReactElement, useEffect, useState } from 'react';
import { OverlayTypeContext } from 'context/overlayContext';
import { IOverlayProps } from './types';
import { IOverlayTypeState } from 'types/overlay-context';
import { SCModalWrapper, SCSpinnerWrapper } from 'styles/global-styles';
import { AuthenticationSessionManagement } from 'components/organisms/AuthenticationSessionManagement';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { IClientContextAllData, IClientContextApiResponse, IClientContextState } from 'types/client-context';
import { getQueryVariables } from 'utils/routeUtilities';
import { EConsentDisclaimer } from 'components/atoms/EConsentDisclaimer';
import { OverlayDisplayTypesEnum, OverlayTypesEnum } from 'enums/OverlayTypes';
import { ClientContextsService } from 'api/ClientContextsService';
import { SCOverlayWrapper } from 'components/organisms/Overlay/styles';
import { TSDialog } from 'components/atoms/TSDialog';
import { DistributorSelector } from 'components/organisms/DistributorSelector';
import { CircularProgress } from '@mui/material';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';

const Overlay = ({ children }: IOverlayProps): ReactElement => {
    const { saveClientContextDataState, clientContextDataState } = React.useContext(
        DistributorConfigurationStateContext
    ) as IClientContextState;
    const { overlayType, saveOverlayType } = React.useContext(OverlayTypeContext) as IOverlayTypeState;
    const [clientConfiguration, setClientConfiguration] = useState<IClientContextAllData>();
    const [isLoading, setIsLoading] = useState(false);
    const [modalEDeliveryState, setModalEDeliveryState] = useState<boolean>(false);
    const queryVariables = getQueryVariables();
    const getClientConfiguration = async (partnerKey: string): Promise<void> => {
        if (
            clientContextDataState.inputPartnerKey == partnerKey &&
            localStorage.getItem(LocalStorageKeys.CLIENT_KEY) == partnerKey
        ) {
            return;
        }
        setIsLoading(true);
        const clientContextsApiResponse: IClientContextApiResponse = (await ClientContextsService(
            partnerKey
        )) as IClientContextApiResponse;
        if (clientContextsApiResponse?.payload) {
            setClientConfiguration(clientContextsApiResponse.payload);
            localStorage.setItem(LocalStorageKeys.CLIENT_KEY, partnerKey);
            saveClientContextDataState(clientContextsApiResponse.payload, partnerKey);
        } else {
            resetPartnerSelection();
        }
        setIsLoading(false);
    };
    const resetPartnerSelection = (): void => {
        localStorage.removeItem(LocalStorageKeys.CLIENT_ID);
        localStorage.removeItem(LocalStorageKeys.CLIENT_KEY);
        saveOverlayType({ type: OverlayTypesEnum.TAKEOVER, reviewState: true });
    };

    useEffect(() => {
        const localStorageClientKey = localStorage.getItem(LocalStorageKeys.CLIENT_KEY);
        if (queryVariables && queryVariables['displayType'] === OverlayDisplayTypesEnum.E_CONSENT_LEGAL) {
            setModalEDeliveryState(true);
        }
        if (
            queryVariables &&
            Object.prototype.hasOwnProperty.call(queryVariables, 'did') &&
            queryVariables['did'] != null
        ) {
            getClientConfiguration(queryVariables['did']);
        } else if (localStorageClientKey) {
            getClientConfiguration(localStorageClientKey as string);
        } else {
            resetPartnerSelection();
        }
    }, [window.location.search]);
    useEffect(() => {
        window.addEventListener('error', (e) => {
            // TODO Resolve underlying issue
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, [window]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::Overlay:::::::::::::::::::::::::::::::::',
    //     "\n::overlayType['reviewState']::",
    //     overlayType['reviewState'],
    //     '\n::queryVariables::',
    //     queryVariables,
    //     '\n::overlayType::',
    //     overlayType,
    //     '\n::clientConfiguration::',
    //     clientConfiguration,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCOverlayWrapper>
            {(!overlayType['reviewState'] && !isLoading && children) ||
                (overlayType['reviewState'] && overlayType['type'] === OverlayTypesEnum.TAKEOVER && !isLoading && (
                    <DistributorSelector></DistributorSelector>
                ))}
            {modalEDeliveryState && (
                <SCModalWrapper>
                    <TSDialog
                        ariaLabel="econsent-dialog"
                        id="econsent-dialog"
                        closeButtonText="Done"
                        open={modalEDeliveryState}
                        fullscreen={true}
                        handleClose={(): void => {
                            setModalEDeliveryState(false);
                        }}
                    >
                        <EConsentDisclaimer />
                    </TSDialog>
                </SCModalWrapper>
            )}
            {isLoading && (
                <SCSpinnerWrapper>
                    <CircularProgress
                        sx={{
                            color: (theme): string => {
                                return theme.palette.common.black;
                            },
                            animationDuration: '1550ms'
                        }}
                        size={100}
                        thickness={4}
                    />
                </SCSpinnerWrapper>
            )}
            <AuthenticationSessionManagement />
        </SCOverlayWrapper>
    );
};

export { Overlay };
