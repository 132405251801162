import { IClientContextApiResponse } from 'types/client-context';
import api from 'utils/api';
import { slotConfig } from 'utils/api';
import { AxiosResponse } from 'axios';
import { env } from 'env';

export const ClientContextsService = async (partnerKey: string): Promise<IClientContextApiResponse | unknown> => {
    try {
        const response = await api.get<IClientContextApiResponse, AxiosResponse<IClientContextApiResponse>>(
            'clientcontexts/' + partnerKey,
            slotConfig
        );
        return response.data;
    } catch (error) {
        console.error('::Get ClientContextsService.getClientContexts API Error::', error);
        return;
    }
};
