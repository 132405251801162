import { IClaimsManagementData } from 'types/claims-management';
import { env } from 'env';
import api, { slotConfig } from 'utils/api';

export const ClaimSearchService = {
    getSearchWithResponse: (): any => {
        return api.get(`${env.REACT_APP_CLAIM_SEARCH_API_URL}`, slotConfig);
    }
};

export const UpdateClaimsBatchService = {
    postClaimWithResponse: (body: IClaimsManagementData[]): any => {
        return api.put(`${env.REACT_APP_CLAIMS_UPDATE_BATCH}`, body, slotConfig);
    }
};
