import * as React from 'react';
import { IChildrenProps } from 'types/children';
import { IClientContextAllData, IClientContextCachedResponse, IClientContextState } from 'types/client-context';

export const DistributorConfigurationStateContext = React.createContext<IClientContextState | null>(null);
const DistributorConfigurationStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [clientContextDataState, setDistributorConfigurationState] = React.useState<IClientContextCachedResponse>({
        inputPartnerKey: '',
        payload: {
            client_content: {
                key: '',
                name: '',
                certificate_of_insurance_uri: '',
                coverage_duration: '',
                distributor_launch_date: '',
                distributor_benefit_equivalency: '',
                distributor_link: '',
                distributor_logo_uri: '',
                adtrax_identifier: '',
                faq_collections: [],
                footer_disclosure: '',
                claim_options: [],
                claim_subHeader: '',
                claim_peril_1: '',
                claim_peril_2: '',
                claim_peril_3: '',
                claim_peril_4: '',
                title: '',
                heading: '',
                sub_heading: '',
                how_it_works_header: '',
                how_it_works_content: '',
                description: '',
                additional_description_header: '',
                additional_description: '',
                key_value_props_1_header: '',
                key_value_props_1_content: '',
                key_value_props_2_header: '',
                key_value_props_2_content: '',
                key_value_props_3_header: '',
                key_value_props_3_content: '',
                need_to_file_claim_content: '',
                need_to_file_claim_header: ''
            },
            loanServicerKey: '',
            loanServicerName: '',
            distributorKey: '',
            distributorName: '',
            borrowerIdentifierType: 0
        }
    });

    const saveClientContextDataState = (
        distributorConfigurationData: IClientContextAllData,
        inputPartnerKey: string
    ): void => {
        const newDistributorConfigurationData: IClientContextCachedResponse = {
            inputPartnerKey: inputPartnerKey,
            payload: {
                client_content: distributorConfigurationData.client_content,
                distributorKey: distributorConfigurationData.distributorKey,
                distributorName: distributorConfigurationData.distributorName,
                loanServicerKey: distributorConfigurationData.loanServicerKey,
                loanServicerName: distributorConfigurationData.loanServicerName,
                borrowerIdentifierType: distributorConfigurationData.borrowerIdentifierType
            }
        };
        setDistributorConfigurationState(newDistributorConfigurationData);
        // console.info(
        //     '\n::::::::::::::::::::::::::::::DistributorConfigurationStateContext::saveDistributorConfigurationDataState:::::::::::::::::::::::::::::::::',
        //     '\n::distributorConfigurationDataState::',
        //     distributorConfigurationDataState,
        //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
        // );
    };

    return (
        <DistributorConfigurationStateContext.Provider value={{ clientContextDataState, saveClientContextDataState }}>
            {children}
        </DistributorConfigurationStateContext.Provider>
    );
};

export default DistributorConfigurationStateProvider;
