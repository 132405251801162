import styled from 'styled-components';

export const SCMarketingContentWrapper = styled.div`
    gap: 16px;
    display: flex;
    flex-direction: column;
`;
export const SCLeftColumnWrapper = styled.div`
    width: 67%;
`;
export const SCRightColumnWrapper = styled.div`
    width: 33%;
    max-width: 420px;
    min-width: 420px;
`;
export const SCParagraph = styled.p``;
export const SCUnorderedList = styled.ul`
    gap: 16px;
    display: flex;
    flex-direction: column;
`;
export const SCOrderedList = styled.ol`
    list-style-type: decimal !important;
    gap: 16px;
    display: flex;
    flex-direction: column;
    li {
        list-style-type: decimal !important;
    }
`;
export const SCBlue = styled.span`
    color: #455eeb;
    font-weight: bold;
`;
export const SCUnderlined = styled.span`
    text-decoration: underline;
`;
