declare global {
    interface Window {
        env: any;
    }
}
// change with your own variables
type EnvType = {
    REACT_APP_USER_ENV: string;
    REACT_APP_CLAIMS_API_URL: string;
    REACT_APP_CLAIM_LOOKUP_API_URL: string;
    REACT_APP_CLAIM_SEARCH_API_URL: string;
    REACT_APP_CLAIMS_UPDATE_BATCH: string;
    REACT_APP_ECONSENT_API_URL: string;
    REACT_APP_CONTENT_API_URL: string;
    REACT_APP_PARTNERS_API_URL: string;
    REACT_APP_CLIENT_CONTEXTS_API_URL: string;
    REACT_APP_CLAIM_ADJUDICATION_CONFIGS_API_URL: string;
    REACT_APP_API_BASE_URL: string;
    REACT_APP_PAYGUARD_APP_GTMID: string;
    REACT_APP_IDP_ISSUER_URL: string;
    REACT_APP_IDP_CLIENT_ID: string;
    REACT_APP_RECAPTCHA_SITE_KEY: string;
    REACT_APP_APIM_SUBSCRIPTION_KEY: string;
    REACT_APP_LAUNCH_DARKLY_SDK_KEY: string;
    REACT_APP_RELEASE_NAME: string; // internal version tracking
    REACT_APP_QUALTRICS: string;
};
export const env: EnvType = { ...process.env, ...window.env };
