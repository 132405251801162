import React, { useState } from 'react';
import {
    SCLabelContentWrapper,
    SCContent,
    SCLabel,
    SCLabelContent,
    SCLabelIcon,
    SCLabelWrapper,
    SCGroupLabel,
    SCTopLine,
    SCExpandContractGroupButton
} from './styles';
import { IAccordionData, IAccordionProps } from './types';
import { doesContainHTML } from 'utils/helpers/helpers';
import { $darkGray, $digitalBlack, SCSubHead } from 'styles/global-styles';

const Accordion = ({ data, id, group_label, group_description, expand_collapse_controls }: IAccordionProps): JSX.Element => {
    const [accordionState, setAccordionState] = useState({});
    const [expandedAll, setExpandedAll] = useState(false);
    const handleStateChange = (stateId: string): void => {
        setAccordionState({
            ...accordionState,
            [stateId]: !accordionState[stateId]
        });
    };

    const handleOpenAll = (): void => {
        {
            data.map((data: IAccordionData, index) => {
                const accordionId = `accordionKey${index}`;
                {
                    expandedAll
                        ? setAccordionState((prevState) => ({
                              ...prevState,
                              [accordionId]: false
                          }))
                        : setAccordionState((prevState) => ({
                              ...prevState,
                              [accordionId]: true
                          }));
                }
            });
        }
        setExpandedAll(!expandedAll);
    };

    return (
        <>
            <SCTopLine data-testid="expand-contract-div" id={`${id}`}>
                {group_label ? <SCSubHead textColor={$darkGray}>{group_label}</SCSubHead> : <></>}
                {expand_collapse_controls ? (
                    <SCExpandContractGroupButton
                        data-testid="expand-contract-label"
                        onClick={(): void => handleOpenAll()}
                    >
                        {expandedAll ? 'Collapse All' : 'Expand All'}
                    </SCExpandContractGroupButton>
                ) : (
                    <></>
                )}
            </SCTopLine>
            {group_description ? <>{group_description}</> : <></>}
            <>
                {data.map((data: IAccordionData, index) => {
                    const accordionId = `accordionKey${index}`;
                    return (
                        <SCLabelContentWrapper key={`accordionKey${index}`}>
                            <SCLabelWrapper
                                onClick={(): void => handleStateChange(accordionId)}
                                data-testid="clickable-div"
                                labelHeight={!accordionState[accordionId]}
                            >
                                <SCLabel paddingBottom={accordionState[accordionId]}>
                                    <SCLabelContent>
                                        {data.label}
                                        <SCLabelIcon transform={!accordionState[accordionId]}>
                                            <svg
                                                data-testid="plus-icon"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.5 10.2424V7.75762H7.75762V0.5H10.2424V7.75762H17.5V10.2424H10.2424V17.5H7.75762V10.2424H0.5Z"
                                                    fill="#608CFF"
                                                />
                                            </svg>
                                        </SCLabelIcon>
                                    </SCLabelContent>
                                </SCLabel>
                            </SCLabelWrapper>
                            {typeof(data.content) == 'string' && doesContainHTML(data.content) ? (
                                <SCContent
                                    visibleState={accordionState[accordionId]}
                                    dangerouslySetInnerHTML={{ __html: data.content }}
                                ></SCContent>
                            ) : (
                                <SCContent visibleState={accordionState[accordionId]}>{data.content}</SCContent>
                            )}
                        </SCLabelContentWrapper>
                    );
                })}
            </>
        </>
    );
};

export { Accordion };
