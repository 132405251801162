import TagManager from 'react-gtm-module';
import { TrackerEnum } from 'enums/TrackerType';
import { env } from 'env';
export class AnalyticsService {
    private static instance: AnalyticsService;
    constructor() {
        this.initializeGTM();
        document.addEventListener(TrackerEnum.PAGE_VIEW, this.sendPageChangeAnalytics);
        document.addEventListener(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, (ev: Event) => {
            if (ev instanceof CustomEvent) {
                const { claimType, distributorName, id } = ev.detail;
                if (id) {
                    switch (id.toLowerCase()) {
                        case 'beginclaimbtn':
                            this.claimProcessAnalytics.sendToDataLayer(
                                claimType,
                                distributorName,
                                TrackerEnum.CLAIMSTEP_START
                            );
                            break;
                        case 'submitclaimsubmissionbtn':
                            this.claimProcessAnalytics.sendToDataLayer(
                                claimType,
                                distributorName,
                                TrackerEnum.CLAIMSTEP_SUBMITTED
                            );
                            break;
                    }
                }
            }
        });
        document.addEventListener(TrackerEnum.DISTRIBUTOR_SELECT, this.sendDIDSelectionPageAnalytics);
    }
    private sendPageChangeAnalytics = (): void => {
        const currentPath = window.location.pathname;
        const event = TrackerEnum.PAGE_VIEW;
        const page = currentPath.split('/');
        TagManager.dataLayer({
            dataLayer: {
                event,
                page_location: currentPath,
                page_title: page
            }
        });
    };
    private sendDIDSelectionPageAnalytics = (): void => {
        const currentPath = window.location.pathname;
        const event = TrackerEnum.PAGE_VIEW;
        const page = 'DID Selection';
        TagManager.dataLayer({
            dataLayer: {
                event,
                page_location: currentPath,
                page_title: page
            }
        });
    };
    private claimProcessAnalytics = {
        sendToDataLayer: (claimType: string, distributorName: string, processState: string): void => {
            TagManager.dataLayer({
                dataLayer: {
                    event: TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS,
                    claimType: claimType,
                    distributorName: distributorName,
                    claimStep: processState
                }
            });
        }
    };
    private initializeGTM = (): void => {
        const tagManagerArgs = {
            gtmId: `${env.REACT_APP_PAYGUARD_APP_GTMID}`
        };
        TagManager.initialize(tagManagerArgs);
    };
    public static init(): AnalyticsService {
        if (!AnalyticsService.instance) {
            AnalyticsService.instance = new AnalyticsService();
        }
        return AnalyticsService.instance;
    }
}
