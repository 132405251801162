import * as React from 'react';
import { IClaimAdjudicationConfigsTypeState } from './ClaimAdjudicationConfigsTypes';
import { IChildrenProps } from 'types/children';
import { IClaimAdjudicationConfigData } from 'types/claim-adjudication-config';

export const ClaimAdjudicationConfigsContext = React.createContext<IClaimAdjudicationConfigsTypeState | null>(null);

const ClaimAdjudicationConfigsProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [stateClaimAdjudicationConfigs, setStateClaimAdjudicationConfigs] =
        React.useState<IClaimAdjudicationConfigData>({
            distributors: [],
            decisionReasons: []
        });
    const resetStateClaimAdjudicationConfigs = (): void => {
        setStateClaimAdjudicationConfigs({
            distributors: [],
            decisionReasons: []
        });
    };
    const stateObject: IClaimAdjudicationConfigsTypeState = {
        contextStateClaimAdjudicationConfigs: stateClaimAdjudicationConfigs,
        updateContextStateClaimAdjudicationConfigs: setStateClaimAdjudicationConfigs,
        resetContextStateClaimAdjudicationConfigs: resetStateClaimAdjudicationConfigs
    };
    return (
        <ClaimAdjudicationConfigsContext.Provider value={stateObject}>
            {children}
        </ClaimAdjudicationConfigsContext.Provider>
    );
};

export default ClaimAdjudicationConfigsProvider;
