import React, { ReactElement, useEffect, useState } from 'react';
import { ITableEnhancedProps } from './types';
import {
    createData,
    createPostClaimSurveyCSVData,
    CSVHeadCells,
    SurveyCSVHeadCells,
    amountPayableSum,
    createCSVData
} from 'components/molecules/TableEnhanced/TableRowData';
import { Data, PostClaimSurveyCSVData, csvData } from 'components/pages/ClaimsManagement';
import { Order, TableHeadEnhanced } from 'components/molecules/TableHeadEnhanced';
import { CircularProgress, FormControl, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ClaimsManagementStateContext } from 'context/claimsManagementContext';
import { ClaimEditContext, IClaimEditType, IClaimEditTypeState } from 'context/ClaimEdit';
import { IClaimsManagementData, IClaimsManagementState } from 'types/claims-management';
import {
    SCTableButtonIconWrapper,
    SCTableButtonWrapper,
    SCTableColumn,
    SCTableOverrides,
    SCTablePagination,
    SCTableRow,
    SCTableTextWrapper
} from 'components/molecules/TableEnhanced/styles';
import { convertToCSV, createCSVDownloadLink, isNumeric } from 'utils/helpers/helpers';
import { TSButton } from 'components/atoms/TSButton';
import { ClaimSearchService, UpdateClaimsBatchService } from 'api/ClaimSearch';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import {
    $digitalBlack,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCElementWrapper,
    SCSectionBody,
    SCSectionSubHead,
    SCSpinnerWrapper,
    SCErrorLabel
} from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum, FormFieldWidthEnum } from 'enums/StyleTypes';
import { TSDialog } from 'components/atoms/TSDialog';
import { ClaimEdit } from 'components/molecules/ClaimEdit';
import { editFormLabels } from 'support/formLabels';
import { AlertContext, IAlertTypeState, IAlertType } from 'context/Alert';
import { AlertTypeEnum } from 'enums/AlertTypes';
import { TSTextField } from 'components/atoms/TSTextField';
import { EditIcon } from 'components/atoms/EditIcon';
import { formatCurrency, getNumberWithoutCommas } from 'utils/form/form';

dayjs.extend(utc);
dayjs.extend(timezone);
const timeZone = 'America/Chicago';
const claimEditSaveDisabledMessage = 'Fix remaining errors before saving';

const TableEnhanced = ({ children }: ITableEnhancedProps): ReactElement => {
    const {
        initializeTableRowStatusState,
        initializeTableDataState,
        claimsManagementEditArrayState,
        claimsManagementTableState,
        updateClaimManagementRecord
    } = React.useContext(ClaimsManagementStateContext) as IClaimsManagementState;
    const { contextStateClaimEdit, updateContextStateClaimEdit } = React.useContext(
        ClaimEditContext
    ) as IClaimEditTypeState;
    const { updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const [claimEditAlert, setClaimEditAlert] = useState<IAlertType>({});
    const clearClaimEditAlert = (): void => {
        setClaimEditAlert({});
    };
    const clearRootAlert = (): void => {
        updateContextStateAlert({});
    };
    const clearAllAlerts = (): void => {
        clearRootAlert();
        clearClaimEditAlert();
    };
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('nameLast');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchNameFirst, setSearchNameFirst] = useState('');
    const [searchNameLast, setSearchNameLast] = useState('');
    const [searchClaimNumber, setSearchClaimNumber] = useState('');
    const [searchSSN, setSearchSSN] = useState('');
    const [claimsManagementTable, setClaimsManagementTable] = useState<IClaimsManagementData[]>([]);
    const [editClaimDialog, setEditClaimDialog] = useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [recordSaveState, setRecordSaveState] = useState(false);
    const [filterTerms, setFilterTerms] = useState({});
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [isClaimEditDataValid, setIsClaimEditDataValid] = useState(true);
    const [updateBatchResponse, setUpdateBatchResponse] = useState<any>();
    const handleClaimEditValidationState = (isDataValid: boolean): void => {
        setIsClaimEditDataValid(isDataValid);
    };
    const product = 'Payment Guard';
    useEffect(() => {
        setClaimsManagementTable(claimsManagementEditArrayState);
    }, [claimsManagementEditArrayState]);
    const rows: Data[] = claimsManagementTable.map(
        (element: IClaimsManagementData, index, array: IClaimsManagementData[]) => {
            return createData(
                element.Borrower.NameFirst ? element.Borrower.NameFirst : '',
                element.Borrower.NameLast,
                element.ClaimInfo.DistributorName,
                element.ClaimInfo.LoanServicerName,
                element.ClaimInfo.ClaimNumber as number,
                element.ClaimInfo.ClaimStatus,
                element.ClaimInfo.JoblossReason,
                element.Borrower.BorrowerIdentifierKey,
                element.ClaimInfo.ClaimCreateDateTimeUTC,
                element.ClaimInfo.Peril,
                element.ClaimInfo.LoanNumber as number,
                element.ClaimInfo.IncurredDate,
                element.ClaimInfo.ClaimDecisionDateTime,
                element.ClaimInfo.ClaimDecision?.toLowerCase(),
                getNumberWithoutCommas(element.ClaimInfo.AmountPayable),
                element.ClaimInfo.DisablingCondition,
                element.ClaimInfo.DecisionReason,
                element.ClaimInfo.DecisionEmailSentDateTime,
                element.ClaimInfo.FastTrack,
                element.ClaimInfo.AdditionalInfo,
                element.Borrower.EConsent?.toLowerCase(),
                element.ClaimInfo.NameOfClaimSpecialist,
                element.ClaimInfo.Notes,
                element.Borrower.SSNLast4,
                element.EditStatus,
                element.ClaimInfo.ClaimBenefits,
                element.Borrower.Email,
                index
            );
        }
    );
    //Handling below code for preparing csv file to handle multiple benefits for claims
    const csvRowsData: csvData[] = [];
    claimsManagementTable.forEach((element) => {
        if (element.ClaimInfo.ClaimBenefits != null && element.ClaimInfo.ClaimBenefits.length != 0) {
            element.ClaimInfo.ClaimBenefits.map((belement, index, array) => {
                csvRowsData.push(
                    createCSVData(
                        element.Borrower.NameFirst ? element.Borrower.NameFirst : '',
                        element.Borrower.NameLast,
                        element.ClaimInfo.DistributorName,
                        element.ClaimInfo.LoanServicerName,
                        element.ClaimInfo.ClaimNumber as number,
                        element.ClaimInfo.JoblossReason,
                        element.Borrower.BorrowerIdentifierKey,
                        element.ClaimInfo.ClaimCreateDateTimeUTC,
                        element.ClaimInfo.Peril,
                        belement.ClaimBenefitId,
                        formatCurrency(amountPayableSum(element.ClaimInfo.ClaimBenefits).toString()),
                        belement.ExternalSystemIdentifier,
                        element.ClaimInfo.IncurredDate,
                        belement.DecisionDateTimeUTC,
                        belement.DecisionStatus.toLowerCase(),
                        formatCurrency(belement.AmountPayable),
                        element.ClaimInfo.DisablingCondition,
                        belement.DecisionReason,
                        belement.DecisionEmailSentDateTimeUTC as string,
                        belement.FastTracked !== undefined ? (belement.FastTracked === true ? 'Yes' : 'No') : '',
                        belement.DecisionAdditionalInfo as string,
                        element.Borrower.EConsent?.toLowerCase(),
                        element.ClaimInfo.NameOfClaimSpecialist,
                        element.ClaimInfo.Notes,
                        element.Borrower.SSNLast4,
                        belement.IsDeleted === true ? 'true' : 'false',
                        element.EditStatus
                    )
                );
            });
        } else {
            csvRowsData.push(
                createCSVData(
                    element.Borrower.NameFirst ? element.Borrower.NameFirst : '',
                    element.Borrower.NameLast,
                    element.ClaimInfo.DistributorName,
                    element.ClaimInfo.LoanServicerName,
                    element.ClaimInfo.ClaimNumber as number,
                    element.ClaimInfo.JoblossReason,
                    element.Borrower.BorrowerIdentifierKey,
                    element.ClaimInfo.ClaimCreateDateTimeUTC,
                    element.ClaimInfo.Peril,
                    formatCurrency(element.ClaimInfo.AmountPayable),
                    '',
                    '',
                    element.ClaimInfo.IncurredDate,
                    element.ClaimInfo.ClaimDecisionDateTime,
                    element.ClaimInfo.ClaimStatus ? element.ClaimInfo.ClaimStatus?.toLowerCase() : 'open',
                    formatCurrency(element.ClaimInfo.AmountPayable),
                    element.ClaimInfo.DisablingCondition,
                    '',
                    element.ClaimInfo.DecisionEmailSentDateTime as string,
                    element.ClaimInfo.FastTrack,
                    element.ClaimInfo.AdditionalInfo,
                    element.Borrower.EConsent?.toLowerCase(),
                    element.ClaimInfo.NameOfClaimSpecialist,
                    element.ClaimInfo.Notes,
                    element.Borrower.SSNLast4,
                    'false',
                    element.EditStatus
                )
            );
        }
    });
    const surveyData: PostClaimSurveyCSVData[] = claimsManagementTable.map((element, index, array) => {
        return createPostClaimSurveyCSVData(
            element.ClaimInfo.ClaimNumber?.toString(),
            element.Borrower.Email,
            product,
            '',
            element.Borrower.NameFirst + ' ' + element.Borrower.NameLast,
            element?.ClaimInfo?.ClaimBenefits &&
                formatCurrency(amountPayableSum(element?.ClaimInfo?.ClaimBenefits).toString()),
            element.Borrower.Address,
            element.ClaimInfo.DistributorName,
            '',
            element.ClaimInfo.LoanNumber?.toString(),
            element.ClaimInfo.LoanNumber?.toString(),
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            element.ClaimInfo.ClaimCreateDateTimeUTC,
            '',
            element.ClaimInfo.ClaimStatus,
            element.ClaimInfo.Peril,
            element.ClaimInfo.ClaimBenefits && element.ClaimInfo.ClaimBenefits[0].DecisionDateTimeUTC !== undefined
                ? element.ClaimInfo.ClaimBenefits[0].DecisionDateTimeUTC
                : ' ',
            element.ClaimInfo.ClaimBenefits && element.ClaimInfo.ClaimBenefits[0].DecisionReason,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            element.Borrower.Phone,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        );
    });
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): undefined | void => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.claimNumber as unknown as string);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };
    const getComparator = <Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
        return order === 'desc'
            ? (a, b): number => descendingComparator(a, b, orderBy)
            : (a, b): number => -descendingComparator(a, b, orderBy);
    };
    // TODO: sort return type(no any)
    const tableSort = <T,>(array: readonly T[], comparator: (a: T, b: T) => number): any => {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            return order !== 0 ? order : a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };
    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [selectedRow, setSelectedRow] = useState(0);
    const handleSelectRow = (rowIndex: React.SetStateAction<number>): void => {
        setSelectedRow(rowIndex);
    };
    const isSelected = (claimNumber: string): boolean => selected.indexOf(claimNumber) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const exportTableToCSV = (): void => {
        const rowsDeepCopy = structuredClone(csvRowsData);
        const filtered = rowsDeepCopy.filter((d) => {
            return d && d.isDeleted !== 'true';
        });
        const csvRows = [...filtered];
        csvRows.map((rowObject, index) => {
            rowObject.isDeleted = '';
            rowObject.claimCreateDate !== undefined &&
                (rowObject.claimCreateDate = `${dayjs
                    .utc(rowObject.claimCreateDate)
                    .tz(timeZone)
                    .format('MMMM D YYYY h:mm A')}`);
            rowObject.dateDecisionEmailSent !== undefined &&
                (rowObject.dateDecisionEmailSent = `${dayjs(rowObject.dateDecisionEmailSent).format('MMMM D YYYY')}`);
            rowObject.dateIncurred !== undefined &&
                (rowObject.dateIncurred = `${dayjs(rowObject.dateIncurred).format('MMMM D YYYY')}`);
            rowObject.dateOfClaimDecision !== undefined &&
                (rowObject.dateOfClaimDecision = `${dayjs(rowObject.dateOfClaimDecision).format('MMMM D YYYY')}`);
            rowObject.eConsent !== undefined && (rowObject.eConsent = rowObject.eConsent === 'true' ? 'Yes' : 'No');
            rowObject.amountPayable !== undefined && (rowObject.amountPayable = `$${rowObject.amountPayable}`);
            rowObject.benefitAmountPayableSumTotal !== undefined &&
                (rowObject.benefitAmountPayableSumTotal = `$${rowObject.benefitAmountPayableSumTotal}`);
        });
        convertToCSV(csvRows, CSVHeadCells);
        createCSVDownloadLink(convertToCSV(csvRows, CSVHeadCells), 'Search Results');
    };
    const exportSurveyListToCSV = (): void => {
        const rowsDeepCopy = structuredClone(surveyData);
        const csvRows = [...rowsDeepCopy];
        csvRows.map((rowObject, index) => {
            rowObject.lastSampleMonth !== undefined &&
                rowObject.lastSampleMonth !== '' &&
                (rowObject.lastSampleMonth = `${dayjs(rowObject.lastSampleMonth).format('MM/DD/YYYY')}`);
            rowObject.claimEnteredDate !== undefined &&
                rowObject.claimEnteredDate !== '' &&
                (rowObject.claimEnteredDate = `${dayjs(rowObject.claimEnteredDate).format('MM/DD/YYYY')}`);
            rowObject.claimPaymentDate !== undefined &&
                rowObject.claimPaymentDate !== '' &&
                (rowObject.claimPaymentDate = `${dayjs(rowObject.claimPaymentDate).format('MM/DD/YYYY')}`);
            rowObject.closeDate !== undefined &&
                rowObject.closeDate !== '' &&
                (rowObject.closeDate = `${dayjs(rowObject.closeDate).format('MM/DD/YYYY')}`);
            rowObject.dateofDeath !== undefined &&
                rowObject.dateofDeath !== '' &&
                (rowObject.dateofDeath = `${dayjs(rowObject.dateofDeath).format('MM/DD/YYYY')}`);
            rowObject.noticeReceivedDate !== undefined &&
                rowObject.noticeReceivedDate !== '' &&
                (rowObject.noticeReceivedDate = `${dayjs(rowObject.noticeReceivedDate).format('MM/DD/YYYY')}`);
            rowObject.noticeReportDate !== undefined &&
                rowObject.noticeReportDate !== '' &&
                (rowObject.noticeReportDate = `${dayjs(rowObject.noticeReportDate).format('MM/DD/YYYY')}`);
            rowObject.paymentDate !== undefined &&
                rowObject.paymentDate !== '' &&
                (rowObject.paymentDate = `${dayjs(rowObject.paymentDate).format('MM/DD/YYYY')}`);
            rowObject.input_Individuals_Birth_Date !== undefined &&
                rowObject.input_Individuals_Birth_Date !== '' &&
                (rowObject.input_Individuals_Birth_Date = `${dayjs(rowObject.input_Individuals_Birth_Date).format(
                    'MM/DD/YYYY'
                )}`);
            rowObject.birth_Date !== undefined &&
                rowObject.birth_Date !== '' &&
                (rowObject.birth_Date = `${dayjs(rowObject.birth_Date).format('MM/DD/YYYY')}`);
            rowObject.contestable !== undefined &&
                rowObject.contestable !== '' &&
                (rowObject.contestable = rowObject.contestable === 'true' ? 'Y' : 'N');
            rowObject.fNOLClaim !== undefined &&
                rowObject.fNOLClaim !== '' &&
                (rowObject.fNOLClaim = rowObject.fNOLClaim === 'true' ? 'Yes' : 'No');
            rowObject.claimPaymentAmount !== undefined &&
                rowObject.claimPaymentAmount !== '' &&
                (rowObject.claimPaymentAmount = `$${rowObject.claimPaymentAmount}`);
            rowObject.coveragePaymentAmt !== undefined &&
                rowObject.coveragePaymentAmt !== '' &&
                (rowObject.coveragePaymentAmt = `$${rowObject.coveragePaymentAmt}`);
        });
        createCSVDownloadLink(convertToCSV(csvRows, SurveyCSVHeadCells), `Claims Survey - ${dayjs().format('MMM')}`);
    };
    const getClaimDetailsUsingLookupIndex = (index: number): IClaimsManagementData | null => {
        if (claimsManagementTable && claimsManagementTable.length > index) {
            return claimsManagementTable[index];
        } else {
            return null;
        }
    };
    const getSearchData = (): void => {
        ClaimSearchService.getSearchWithResponse()
            .then((res) => {
                if (res.data) {
                    const data = res.data as IClaimsManagementData[];
                    initializeTableRowStatusState(data);
                    initializeTableDataState(data);
                }
                setIsLoading(false);
                setEditClaimDialog(false);
                updateContextStateAlert({
                    type: AlertTypeEnum.SUCCESS,
                    dismissible: true,
                    message:
                        updateBatchResponse && updateBatchResponse.data.payload[0].message
                            ? `${updateBatchResponse && updateBatchResponse.data.payload[0].message}`
                            : 'Claims Updated Successfully!',
                    active: true
                });
            })
            .catch((error) => {
                console.error(
                    '\n::::::::::::::::::::::::::::GetSearchData::ERROR::::::::::::::::::::::::::::',
                    '\n::error::',
                    error,
                    '\n::error.message::',
                    error.message,
                    '\n::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
                );
                setIsLoading(false);
                setEditClaimDialog(false);
            });
    };
    useEffect(() => {
        if (recordSaveState) {
            const updatedRecord = claimsManagementEditArrayState.find((x) => {
                return x.ClaimInfo.ClaimNumber === contextStateClaimEdit.claimNumber;
            });
            setIsLoading(true);
            updatedRecord &&
                UpdateClaimsBatchService.postClaimWithResponse([updatedRecord])
                    .then((response: any): void => {
                        setUpdateBatchResponse(response);
                        getSearchData();
                        setRecordSaveState(false);
                    })
                    .catch((error: { message: any }): void => {
                        console.error(
                            '\n:::::::::::::::::::::::UpdateClaimsBatchService::ERROR:::::::::::::::::::::::',
                            '\n::error::',
                            error,
                            '\n::error.message::',
                            error.message,
                            '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
                        );
                        setEditClaimDialog(false);
                        setIsLoading(false);
                        setRecordSaveState(false);
                        updateContextStateAlert({
                            type: AlertTypeEnum.WARNING,
                            dismissible: true,
                            message: error.message ? `${error.message}` : 'Claims Update Failed !!',
                            active: true
                        });
                    });
        }
    }, [claimsManagementEditArrayState, recordSaveState]);
    useEffect(() => {
        if (Object.keys(filterTerms).length !== 0) {
            const keys: string[] = Object.keys(filterTerms);
            const values: number | string[] = Object.values(filterTerms);
            const keyIndex1: number = keys.indexOf('BorrowerIdentifierKey');
            const keyIndex2: number = keys.indexOf('ClaimNumber');
            const keyIndex3: number = keys.indexOf('NameLast');
            const keyIndex4: number = keys.indexOf('NameFirst');
            const filterMatches: IClaimsManagementData[] = claimsManagementTableState.filter((claimObject) => {
                return (
                    String(claimObject.Borrower.BorrowerIdentifierKey).toLowerCase().match(values[keyIndex1]) &&
                    String(claimObject.ClaimInfo.ClaimNumber).toLowerCase().match(values[keyIndex2]) &&
                    String(claimObject.Borrower.NameLast).toLowerCase().match(values[keyIndex3]) &&
                    String(claimObject.Borrower.NameFirst).toLowerCase().match(values[keyIndex4])
                );
            });
            setClaimsManagementTable(filterMatches);
        }
    }, [filterTerms, recordSaveState, claimsManagementTableState, claimsManagementEditArrayState]);
    // console.info(
    //     '\n:::::::::::::::::::::::::TableEnhanced:::::::::::::::::::::::::::',
    //     '\n::claimsManagementEditArrayState::',
    //     claimsManagementEditArrayState,
    //     '\n::rows::',
    //     rows,
    //     '\n::contextStateClaimEdit::',
    //     contextStateClaimEdit,
    //     '\n::claimsManagementTable::',
    //     claimsManagementTable,
    //     '\n::contextStateClaimEdit.amountPayable::',
    //     contextStateClaimEdit.amountPayable,
    //     '\n::claimsManagementTable[0].ClaimInfo.AmountPayable::',
    //     claimsManagementTable[0]?.ClaimInfo.AmountPayable,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <>
            {!isLoading && (
                <>
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexGap={'24px'}
                    >
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.CENTER}
                            flexGap={'0'}
                        >
                            <SCTableColumn>
                                <SCContentSectionWrapper
                                    flexDirection={FlexDirectionEnum.ROW}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                    flexJustifyContent={FlexJustifyContentEnum.FLEX_START}
                                    flexGap={'24px'}
                                >
                                    <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                        <TSTextField
                                            id="NameFirst"
                                            value={searchNameFirst}
                                            type={'search'}
                                            ariaLabel={'First Name filter'}
                                            label={editFormLabels.borrowerNameFirst}
                                            onChange={(event): void => {
                                                if (event.target.value.length !== -1 || event.target.value === '') {
                                                    setFilterTerms({
                                                        ...filterTerms,
                                                        [event.target.id]: event.target.value.toLowerCase()
                                                    });
                                                    handleChangePage(event, 0);
                                                    setSearchNameFirst(event.target.value);
                                                }
                                            }}
                                            maxLength={27}
                                            multiline={false}
                                        />
                                    </SCElementWrapper>
                                    <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                        <TSTextField
                                            id="NameLast"
                                            value={searchNameLast}
                                            type={'search'}
                                            ariaLabel={'Last Name filter'}
                                            label={editFormLabels.borrowerNameLast}
                                            onChange={(event): void => {
                                                if (event.target.value.length !== -1 || event.target.value === '') {
                                                    setFilterTerms({
                                                        ...filterTerms,
                                                        [event.target.id]: event.target.value.toLowerCase()
                                                    });
                                                    handleChangePage(event, 0);
                                                    setSearchNameLast(event.target.value);
                                                }
                                            }}
                                            maxLength={25}
                                            multiline={false}
                                        />
                                    </SCElementWrapper>
                                    <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                        <TSTextField
                                            id="ClaimNumber"
                                            value={searchClaimNumber}
                                            type={'search'}
                                            ariaLabel={'Claim Number filter'}
                                            label={editFormLabels.claimNumber}
                                            onChange={(event): void => {
                                                if (
                                                    isNumeric(event.target.value.slice(-1)) ||
                                                    event.target.value === ''
                                                ) {
                                                    setFilterTerms({
                                                        ...filterTerms,
                                                        [event.target.id]: event.target.value
                                                    });
                                                    handleChangePage(event, 0);
                                                    setSearchClaimNumber(event.target.value);
                                                }
                                            }}
                                            maxLength={10}
                                            multiline={false}
                                        />
                                    </SCElementWrapper>
                                    <SCElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                                        <TSTextField
                                            id="BorrowerIdentifierKey"
                                            value={searchSSN}
                                            type={'search'}
                                            ariaLabel={'Borrower Identifier Key filter'}
                                            label={editFormLabels.claimIdentifier}
                                            onChange={(event): void => {
                                                if (
                                                    isNumeric(event.target.value.slice(-1)) ||
                                                    event.target.value === ''
                                                ) {
                                                    setFilterTerms({
                                                        ...filterTerms,
                                                        [event.target.id]: event.target.value
                                                    });
                                                    handleChangePage(event, 0);
                                                    setSearchSSN(event.target.value);
                                                }
                                            }}
                                            maxLength={4}
                                            multiline={false}
                                        />
                                    </SCElementWrapper>
                                </SCContentSectionWrapper>
                            </SCTableColumn>
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.ROW}
                                flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                flexJustifyContent={FlexJustifyContentEnum.FLEX_END}
                                flexGap={'24px'}
                            >
                                <TSButton
                                    ariaLabel={'search'}
                                    tsVariant={TSButtonVariantsEnum.TEXT}
                                    size={TSButtonSizeEnum.SMALL}
                                    disabled={false}
                                    onClick={(): void => {
                                        setSearchNameFirst('');
                                        setSearchNameLast('');
                                        setSearchClaimNumber('');
                                        setSearchSSN('');
                                        setFilterTerms({});
                                        setClaimsManagementTable(claimsManagementEditArrayState);
                                        setSelectedRow(0);
                                    }}
                                >
                                    Clear All Search
                                </TSButton>
                            </SCContentSectionWrapper>
                        </SCContentSectionWrapper>
                        <TableContainer>
                            <SCTableOverrides>
                                <Table aria-labelledby="tableTitle" size={'small'}>
                                    <TableHeadEnhanced
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {tableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: IClaimEditType, index: number) => {
                                                const isItemSelected = isSelected(row.claimNumber as unknown as string);
                                                return (
                                                    <SCTableRow
                                                        key={`table-row-${index}`}
                                                        id={`row-id-${index}`}
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        hover
                                                        selected={selectedRow === index + 1}
                                                        onClick={(): void => {
                                                            return handleSelectRow(index + 1);
                                                        }}
                                                    >
                                                        <TableCell align="right">
                                                            <SCTableButtonWrapper>
                                                                <TSButton
                                                                    ariaLabel={'Edit Claim icon'}
                                                                    tsVariant={TSButtonVariantsEnum.TEXT}
                                                                    onClick={(): void => {
                                                                        handleSelectRow(index + 1);
                                                                        setEditClaimDialog(true);
                                                                        updateContextStateClaimEdit(row);
                                                                        clearAllAlerts();
                                                                    }}
                                                                >
                                                                    <SCTableButtonIconWrapper>
                                                                        <EditIcon />
                                                                    </SCTableButtonIconWrapper>
                                                                </TSButton>
                                                            </SCTableButtonWrapper>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '15ch' }}
                                                                variant="standard"
                                                            >
                                                                <SCTableTextWrapper>{row.nameFirst}</SCTableTextWrapper>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '15ch' }}
                                                                variant="standard"
                                                            >
                                                                <SCTableTextWrapper>{row.nameLast}</SCTableTextWrapper>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '15ch' }}
                                                                variant="standard"
                                                            >
                                                                {row.distributorName}
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '15ch' }}
                                                                variant="standard"
                                                            >
                                                                {row.claimNumber}
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '15ch' }}
                                                                variant="standard"
                                                            >
                                                                {row.borrowerIdentifierKey}
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '25ch' }}
                                                                variant="standard"
                                                            >
                                                                {dayjs
                                                                    .utc(row.claimCreateDate as string)
                                                                    .tz(timeZone)
                                                                    .format('MMMM D YYYY h:mm A')}
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                fullWidth
                                                                sx={{ m: 1, width: '15ch' }}
                                                                variant="standard"
                                                            >
                                                                {row.peril}
                                                            </FormControl>
                                                        </TableCell>
                                                    </SCTableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </SCTableOverrides>
                        </TableContainer>
                        <SCTablePagination>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </SCTablePagination>
                    </SCContentSectionWrapper>
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.ROW}
                        flexAlignment={FlexAlignItemsEnum.FLEX_END}
                        flexJustifyContent={FlexJustifyContentEnum.FLEX_END}
                        flexGap={'24px'}
                    >
                        <TSButton
                            tsVariant={TSButtonVariantsEnum.UNDERLINE}
                            size={TSButtonSizeEnum.LARGE}
                            ariaLabel={'button to output csv of survey list'}
                            onClick={(): void => {
                                exportSurveyListToCSV();
                            }}
                        >
                            Post Claim Survey List
                        </TSButton>
                        <TSButton
                            tsVariant={TSButtonVariantsEnum.UNDERLINE}
                            size={TSButtonSizeEnum.LARGE}
                            ariaLabel={'button to output csv of table content'}
                            onClick={(): void => {
                                exportTableToCSV();
                            }}
                        >
                            Download CSV of Search Results
                        </TSButton>
                    </SCContentSectionWrapper>
                </>
            )}
            <TSDialog
                id={'ClaimEditDialog'}
                ariaLabel={'claim edit dialog'}
                bannerHeaderText={`${editFormLabels.claimNumber}: ${contextStateClaimEdit?.claimNumber}`}
                bannerHeaderCloseButtonText={'Close'}
                closeButtonText={'Close'}
                hideSecondaryCloseButton={true}
                open={editClaimDialog}
                fullscreen={true}
                handleClose={(): void => {
                    !contextStateClaimEdit.isDirty && setEditClaimDialog(false);
                    contextStateClaimEdit.isDirty && setConfirmModalIsVisible(true);
                }}
                actionChildren={
                    <SCContentSectionResponsiveWrapper
                        flexJustifyContent={FlexJustifyContentEnum.CENTER}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexDirection={FlexDirectionEnum.ROW}
                        flexGap={'24px'}
                    >
                        {!isClaimEditDataValid && (
                            <SCErrorLabel visibility={'hidden'}>{claimEditSaveDisabledMessage}</SCErrorLabel>
                        )}
                        <TSButton
                            ariaLabel="dialog Cancel"
                            tsVariant={TSButtonVariantsEnum.TEXT}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                !contextStateClaimEdit.isDirty && setEditClaimDialog(false);
                                contextStateClaimEdit.isDirty && setConfirmModalIsVisible(true);
                            }}
                        >
                            Cancel
                        </TSButton>
                        <TSButton
                            ariaLabel="dialog Save"
                            tsVariant={TSButtonVariantsEnum.ARROW}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                !isLoading && updateClaimManagementRecord(contextStateClaimEdit);
                                !isLoading && setRecordSaveState(true);
                            }}
                            disabled={!isClaimEditDataValid || isLoading}
                        >
                            Save
                        </TSButton>
                        {/**
                         * Error message to show next to Save button if our data is not valid and we are disabling saving because of that
                         * */}
                        {!isClaimEditDataValid && <SCErrorLabel> {claimEditSaveDisabledMessage}</SCErrorLabel>}
                    </SCContentSectionResponsiveWrapper>
                }
                alert={claimEditAlert}
                handleAlertDismiss={clearClaimEditAlert}
            >
                <>
                    {!isLoading && (
                        <ClaimEdit
                            isDataValidCallback={(isClaimEditDataValid: boolean): void => {
                                handleClaimEditValidationState(isClaimEditDataValid);
                            }}
                            getAllClaimDetailsCallback={(dataIndex: number): any => {
                                return getClaimDetailsUsingLookupIndex(dataIndex);
                            }}
                        />
                    )}
                    {isLoading && (
                        <SCSpinnerWrapper width={'100%'}>
                            <CircularProgress
                                sx={{
                                    color: (theme): string => {
                                        return theme.palette.common.black;
                                    },
                                    animationDuration: '1550ms'
                                }}
                                size={100}
                                thickness={4}
                            />
                        </SCSpinnerWrapper>
                    )}
                </>
            </TSDialog>
            {confirmModalIsVisible && (
                <TSDialog
                    ariaLabel={'Unsaved Changes dialog'}
                    hideSecondaryCloseButton
                    handleClose={(): void => {
                        setConfirmModalIsVisible(false);
                    }}
                    id={'unsavedChangesDialog'}
                    open={confirmModalIsVisible}
                    dialogSize={'sm'}
                    bannerHeaderCloseButtonText={'Close'}
                    content={
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.FLEX_START}
                            flexGap={'24px'}
                        >
                            <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                            <SCSectionBody>
                                You have unsaved claim information, save or continue without saving.
                            </SCSectionBody>
                        </SCContentSectionWrapper>
                    }
                    actionChildren={
                        <SCContentSectionResponsiveWrapper
                            flexJustifyContent={FlexJustifyContentEnum.SPACE_AROUND}
                            flexAlignment={FlexAlignItemsEnum.CENTER}
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexGap={'16px'}
                        >
                            <TSButton
                                ariaLabel="dialog return to page"
                                tsVariant={TSButtonVariantsEnum.TEXT}
                                size={TSButtonSizeEnum.MEDIUM}
                                onClick={(): void => {
                                    setConfirmModalIsVisible(false);
                                }}
                            >
                                Return to page
                            </TSButton>
                            <TSButton
                                ariaLabel="dialog proceed without saving"
                                tsVariant={TSButtonVariantsEnum.ARROW}
                                size={TSButtonSizeEnum.MEDIUM}
                                onClick={(): void => {
                                    setConfirmModalIsVisible(false);
                                    setEditClaimDialog(false);
                                }}
                            >
                                Proceed without saving
                            </TSButton>
                        </SCContentSectionResponsiveWrapper>
                    }
                ></TSDialog>
            )}
            {isLoading && (
                <SCSpinnerWrapper>
                    <CircularProgress
                        sx={{
                            color: (theme): string => {
                                return theme.palette.common.black;
                            },
                            animationDuration: '1550ms'
                        }}
                        size={100}
                        thickness={4}
                    />
                </SCSpinnerWrapper>
            )}
        </>
    );
};

export { TableEnhanced };
