export const Routes = {
    CLAIM: '/claim',
    CLAIM_LOOKUP: '/claim-lookup',
    CLAIMS_MANAGEMENT: '/claims-management',
    CONTACT: '/contact',
    ERROR: '/error',
    HOME: '/home',
    LOG_IN: '/login',
    ADMIN_LANDING: '/admin',
    TERMS_OF_USE: '/terms-of-use',
    CALLBACK: '/callback',
    PDF_VIEWER: '/pdf-viewer',
    CLAIM_CONFIRMATION: '/claim-confirmation',
    FAQS: '/faqs',
    ADMIN_GUIDE: '/admin_guide',
    MARKETING_GUIDELINES: '/marketing-guidelines'
};
