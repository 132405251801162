export const distributorImages = {
    hap: 'happy-money.svg',
    zir: 'Zirtue-icon.svg',
    spl: 'Splash-icon.svg',
    bee: 'beem-icon.svg',
    lis: 'listo-logo.svg',
    qca: 'QCash-icon.svg',
    way: 'Way.com-icon.svg',
    bas: 'Basis-icon.svg'
};
