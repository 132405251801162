import React, { ReactElement, useEffect, useState } from 'react';
import { IClaimLookupResponseDataState } from 'types/claim-lookup';
import {
    SCBackButton,
    SCBenefitHeader,
    SCColumnWrapper,
    SCContentWrapper,
    SCCustomElementWrapper,
    SCDivider,
    SCLabelSmallBold,
    SCLineWrapper,
    SCSingleLineWrapper,
    SCSmallLabel,
    SCTableHeadWrapper,
    SCTableOverrides,
    SCValue,
    SCValueBold
} from './styles';
import { FormControl, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { IClaimLookupResultProps } from './types';
import {
    SCBodyMedium,
    SCContentSectionWrapper,
    SCFormRefWrapper,
    SCHeroHeader,
    SCSectionWrapper,
    SCTableContent
} from 'styles/global-styles';
import { ClaimLookupDataStateContext } from 'context/claimLookupDataContext';
import { labelMapIU } from './fieldTypes';
import { Routes } from 'routes';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { useHistory } from 'react-router-dom';
import {
    FlexAlignItemsEnum,
    FlexDirectionEnum,
    FormFieldWidthEnum,
    TableContentOverflowWrapEnum
} from 'enums/StyleTypes';
import { isPhoneIdentifierType } from 'utils/helpers/helpers';
import { headCells } from 'components/organisms/ClaimLookupTableHead';
import { BenefitDataClaimLookup } from 'components/pages/ClaimLookup/types';
import { formatCurrency } from 'utils/form/form';
import { IClientContextState } from 'types/client-context';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';

const ClaimLookupResult = ({ children, data }: IClaimLookupResultProps): ReactElement => {
    const { claimLookupResponseDataState, saveClaimLookupResponseDataState } = React.useContext(
        ClaimLookupDataStateContext
    ) as IClaimLookupResponseDataState;
    const { clientContextDataState } = React.useContext(
        DistributorConfigurationStateContext
    ) as IClientContextState;
    const [isBorrowerIdentifierPhone, setIsBorrowerIdentifierPhone] = React.useState(false);
    const history = useHistory();
    const [referrer] = useState<any>(
        history.location.state || {
            from: Routes.CLAIM_LOOKUP
        }
    );
    const createData = (
        loanNumber: string,
        status: string,
        dateOfBenefitDecision: string,
        amount: string
    ): BenefitDataClaimLookup => {
        return {
            loanNumber,
            status,
            dateOfBenefitDecision,
            amount
        };
    };
    const valueOrNAIfEmpty = (value: string | undefined): string => {
        return value || 'N/A';
    };
    const emptyClaimBenefit: BenefitDataClaimLookup = createData('', '', '', '');
    const rows =
        claimLookupResponseDataState?.claimBenefits == null || claimLookupResponseDataState?.claimBenefits?.length == 0
            ? [emptyClaimBenefit]
            : claimLookupResponseDataState?.claimBenefits.map((element, index, array) => {
                  return createData(
                      element.externalSystemIdentifier,
                      element.decisionStatus,
                      element.decisionDateTimeUTC,
                      element.amountPayable
                  );
              });
    useEffect(() => {
        if (clientContextDataState?.payload?.borrowerIdentifierType != null) {
            setIsBorrowerIdentifierPhone(isPhoneIdentifierType(clientContextDataState.payload.borrowerIdentifierType));
        }
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ClaimLookupResult:::::::::::::::::::::::::::::::::',
    //     '\n::claimLookupResponseDataState::',
    //     claimLookupResponseDataState,
    //     '\n::data::',
    //     data,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCFormRefWrapper>
            <SCBackButton>
                <TSButton
                    id="submitClaimLookupBtn"
                    tsVariant={TSButtonVariantsEnum.BACK}
                    size={TSButtonSizeEnum.SMALL}
                    ariaLabel="submitClaimLookupBtn"
                    onClick={(): void => {
                        saveClaimLookupResponseDataState({
                            ...claimLookupResponseDataState,
                            shouldDisplayResult: false,
                            shouldDisplayNoResult: false
                        });
                        referrer?.from !== Routes.CLAIM_LOOKUP ? history.push(Routes.CLAIMS_MANAGEMENT) : '';
                    }}
                >
                    {'Back'}
                </TSButton>
            </SCBackButton>
            <SCContentSectionWrapper
                flexDirection={FlexDirectionEnum.COLUMN}
                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                flexGap={'36px'}
            >
                <SCHeroHeader>Check Claim Status: Results</SCHeroHeader>
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexGap={'36px'}
                >
                    <SCBodyMedium>
                        We found the following claim record matching your search criteria. If this is not the record
                        you’re looking for, please back up and check your search criteria for accuracy or contact us at
                        1-855-997-7272.
                    </SCBodyMedium>
                </SCContentSectionWrapper>
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexGap={'36px'}
                >
                    <SCSingleLineWrapper>
                        <SCCustomElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                            <SCSmallLabel>{labelMapIU['claimNumber']}:</SCSmallLabel>
                            <SCValueBold>{valueOrNAIfEmpty(data?.claimNumber)}</SCValueBold>
                        </SCCustomElementWrapper>
                        <SCDivider verticalWidth={true} />
                        <SCCustomElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                            <SCSmallLabel>{labelMapIU['peril']}:</SCSmallLabel>
                            <SCValueBold>{valueOrNAIfEmpty(data?.peril)}</SCValueBold>
                        </SCCustomElementWrapper>
                        <SCDivider verticalWidth={true} />
                        <SCCustomElementWrapper widthAmount={FormFieldWidthEnum.SINGLE}>
                            <SCSmallLabel>{labelMapIU['status']}:</SCSmallLabel>
                            <SCValueBold>{valueOrNAIfEmpty(data?.claimStatus)}</SCValueBold>
                        </SCCustomElementWrapper>
                    </SCSingleLineWrapper>
                    <SCDivider verticalWidth={false} />
                    <SCContentWrapper>
                        <SCColumnWrapper>
                            <SCLineWrapper>
                                <SCLabelSmallBold>{labelMapIU['borrowerName']}:</SCLabelSmallBold>
                                <SCValue>
                                    {data?.borrowerFirstName} {data?.borrowerLastName}
                                </SCValue>
                            </SCLineWrapper>
                            <SCLineWrapper>
                                <SCLabelSmallBold>
                                    {isBorrowerIdentifierPhone
                                        ? labelMapIU['borrowerIdentifierKey'][1]
                                        : labelMapIU['borrowerIdentifierKey'][0]}
                                </SCLabelSmallBold>
                                <SCValue>
                                    {valueOrNAIfEmpty(
                                        isBorrowerIdentifierPhone
                                            ? data?.borrowerPhoneNumber?.substring(data?.borrowerPhoneNumber.length - 4)
                                            : data?.borrowerIdentifierKey
                                    )}
                                </SCValue>
                            </SCLineWrapper>
                            <SCLineWrapper>
                                <SCLabelSmallBold>{labelMapIU['distributorName']}:</SCLabelSmallBold>
                                <SCValue>{valueOrNAIfEmpty(data?.distributorName)}</SCValue>
                            </SCLineWrapper>
                        </SCColumnWrapper>
                        <SCColumnWrapper>
                            <SCLineWrapper>
                                <SCLabelSmallBold>{labelMapIU['incurredDate']}:</SCLabelSmallBold>
                                <SCValue>{valueOrNAIfEmpty(data?.incurredDate)}</SCValue>
                            </SCLineWrapper>
                            <SCLineWrapper>
                                <SCLabelSmallBold>{labelMapIU['dateFiled']}:</SCLabelSmallBold>
                                <SCValue>{valueOrNAIfEmpty(data?.filedDate)}</SCValue>
                            </SCLineWrapper>
                        </SCColumnWrapper>
                    </SCContentWrapper>
                </SCContentSectionWrapper>
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexGap={'36px'}
                >
                    <SCBenefitHeader>{'Benefit Status'}</SCBenefitHeader>
                    <SCContentWrapper>
                        <TableContainer>
                            <SCTableOverrides>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map((headCell) => (
                                                <SCTableHeadWrapper
                                                    key={headCell.id}
                                                    align={headCell.numeric ? 'right' : 'left'}
                                                >
                                                    {headCell.label}
                                                </SCTableHeadWrapper>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            return (
                                                <TableRow key={`table-row-${index}`}>
                                                    <TableCell padding="none" align="left">
                                                        <FormControl variant="standard">
                                                            <SCTableContent
                                                                overflowWrap={TableContentOverflowWrapEnum.ACTIVE}
                                                            >
                                                                {valueOrNAIfEmpty(row.loanNumber)}
                                                            </SCTableContent>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell padding="none" align="left">
                                                        <FormControl variant="standard">
                                                            <SCTableContent textTransform={'uppercase'}>
                                                                {valueOrNAIfEmpty(row.status)}
                                                            </SCTableContent>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell padding="none" align="left">
                                                        <FormControl variant="standard">
                                                            <SCTableContent>
                                                                {valueOrNAIfEmpty(row.dateOfBenefitDecision)}
                                                            </SCTableContent>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell padding="none" align="left">
                                                        <FormControl variant="standard">
                                                            <SCTableContent>
                                                                {valueOrNAIfEmpty(
                                                                    row.amount && `$${formatCurrency(row.amount)}`
                                                                )}
                                                            </SCTableContent>
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </SCTableOverrides>
                        </TableContainer>
                    </SCContentWrapper>
                </SCContentSectionWrapper>
            </SCContentSectionWrapper>
            {claimLookupResponseDataState && claimLookupResponseDataState.shouldDisplayNoResult && (
                <SCSectionWrapper>
                    <SCHeroHeader>Check Claim Status: No Matching Result</SCHeroHeader>
                    <p>
                        We were unable to find any claim records matching your search criteria.Please back up and check
                        your search criteria for accuracy or contact us at 1-855-997-7272.
                    </p>
                </SCSectionWrapper>
            )}
        </SCFormRefWrapper>
    );
};

export { ClaimLookupResult };
