import api from 'utils/api';
import { slotConfig } from 'utils/api';
import { AxiosResponse } from 'axios';
import { env } from 'env';
import { IPartnersApiResponse } from 'types/partners';

export const PartnersService = async (): Promise<IPartnersApiResponse | unknown> => {
    try {
        const response = await api.get<IPartnersApiResponse, AxiosResponse<IPartnersApiResponse>>(
            'partners',
            slotConfig
        );
        return response.data;
    } catch (error) {
        console.error('::Get PartnersService.getPartners API Error::', error);
        return;
    }
};
