import { env } from 'env';
import api from 'utils/api';
import { slotConfig } from 'utils/api';

export const ClaimsService = {
    postClaim: (body: FormData): void => {
        api.post(`${env.REACT_APP_CLAIMS_API_URL}`, body, slotConfig);
    },

    postClaimWithResponse: (body: FormData): any => {
        return api.post(`${env.REACT_APP_CLAIMS_API_URL}`, body, slotConfig);
    }
};
