export enum TrackerEnum {
    PAGE_VIEW = 'page_view',
    HOME = 'HOME',
    CLAIM = 'CLAIM',
    CONTACT = 'CONTACT',
    DISTRIBUTOR_SELECT = 'distributor_select',
    CLAIMSTEP_START = 'Begin',
    CLAIMSTEP_SUBMITTED = 'Submitted',
    PAYMENT_GUARD_CLAIMPROCESS = 'paymentGuardClaim'
}
