import React from 'react';

import { SCHeader, SCKeyValueListWrapper, SCKeyValueSectionWrapper } from './styles';
import { IFormSummarySectionProps } from './types';
import { SCClaimSubSectionHead, SCHeadline } from 'styles/global-styles';
import { TSButton } from 'components/atoms/TSButton';
import { KeyValueDisplay } from 'components/atoms/KeyValueDisplay';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { IClientContextState } from 'types/client-context';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { labelMapIU } from 'components/organisms/FormIU/fieldTypes';
import { FormTypeContext } from 'context/formTypeContext';
import { ClaimTypesEnum, TimelineState } from 'enums';
import { TSButtonLetterSpacingEnum, TSButtonVariantsEnum, TSButtonSizeEnum } from 'enums/TSButtonVariants';
import { IFormTypeState } from 'types/claim-types';
import { IFormDisabilityProgressState, IFormJoblossProgressState } from 'types/form-progress';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { ClaimStateEnum } from 'enums/ClaimState';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { isLast4SSNIdentifierType } from '../../../utils/helpers/helpers';

function valueCheck(key: string, value: string, formData: any): string {
    switch (value) {
        case 'true':
            value = 'yes';
            break;
        case 'false':
            value = 'no';
            break;
    }
    switch (key) {
        case 'borrowerNameFirst':
            value = formData['borrowerNameFirst'] + ' ' + formData['borrowerNameLast'];
            break;
        case 'submitterNameFirst':
            if (formData['submitterNameFirst'] == null) {
                formData['submitterNameFirst'] = '';
            }
            if (formData['submitterNameLast'] == null) {
                formData['submitterNameLast'] = '';
            }
            value = formData['submitterNameFirst'] + ' ' + formData['submitterNameLast'];
            break;
    }
    return value;
}
function keyCheck(key: string): string {
    switch (key) {
        case 'borrowerNameFirst':
            key = 'borrowerName';
            break;
        case 'borrowerNameLast':
            key = 'SKIP';
            break;
        case 'submitterNameFirst':
            key = 'submitterName';
            break;
        case 'submitterNameLast':
            key = 'SKIP';
            break;
    }

    return key;
}
const FormSummaryInd = (formData: any, columnData: any): JSX.Element => {
    return (
        <SCKeyValueListWrapper>
            {Object.keys(formData).map((key: any) => {
                let value = formData[key];
                value = valueCheck(key, value, formData);
                key = keyCheck(key);
                if (columnData.includes(key)) {
                    return <KeyValueDisplay key={key} label={labelMapIU[key]} value={value}></KeyValueDisplay>;
                }
            })}
        </SCKeyValueListWrapper>
    );
};
const FormSummarySection = ({
    title,
    id,
    formData,
    claimType,
    Column1Row1,
    Column1Row2,
    Column2Row1,
    Column2Row2
}: IFormSummarySectionProps): JSX.Element => {
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const { clientContextDataState } = React.useContext(
        DistributorConfigurationStateContext
    ) as IClientContextState;
    const { updateFormJoblossProgressState } = React.useContext(
        FormJoblossProgressStateContext
    ) as IFormJoblossProgressState;
    const { updateFormDisabilityProgressState } = React.useContext(
        FormDisabilityProgressStateContext
    ) as IFormDisabilityProgressState;
    const { saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    formData && clientContextDataState?.payload?.borrowerIdentifierType != null && !isLast4SSNIdentifierType(clientContextDataState.payload.borrowerIdentifierType) && 'borrowerSSN' in formData
        ? delete formData.borrowerSSN
        : null;

    return (
        <>
            <SCHeader>
                <SCHeadline>{title}</SCHeadline>
                <TSButton
                    letterSpacing={TSButtonLetterSpacingEnum.NARROW}
                    tsVariant={TSButtonVariantsEnum.UNDERLINE}
                    size={TSButtonSizeEnum.SMALL}
                    ariaLabel={'Button for editing ' + title}
                    onClick={(): void => {
                        saveFormType({ type: claimType, reviewState: ClaimStateEnum.ACTIVE });
                        updateContextStateClaimFormAdvancement({
                            reviewState: ClaimStateEnum.ACTIVE
                        });
                        if (claimType === ClaimTypesEnum.Jobloss) {
                            updateFormJoblossProgressState(id, TimelineState.ACTIVE);
                        } else {
                            updateFormDisabilityProgressState(id, TimelineState.ACTIVE);
                        }
                    }}
                >
                    Edit Section
                </TSButton>
            </SCHeader>
            <SCKeyValueSectionWrapper>
                {FormSummaryInd(formData, Column1Row1)}
                {Column1Row2 != null && FormSummaryInd(formData, Column1Row2)}
            </SCKeyValueSectionWrapper>
            <SCKeyValueSectionWrapper>
                {Column2Row1 != null && FormSummaryInd(formData, Column2Row1)}
                {Column2Row2 != null && FormSummaryInd(formData, Column2Row2)}
            </SCKeyValueSectionWrapper>
        </>
    );
};

export { FormSummarySection };
